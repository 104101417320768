* {
    box-sizing: border-box;
}

html {
    width: 100%;
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Poppins', sans-serif;
    color: #231F20;
    background-color: #fff;
    width: 100%;
    padding: 0;
    margin: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

select {
    -webkit-appearance: none;
}

.bg-blue {
    background-color: #5367fc;
}

.bg-green {
    background-color: #00FFC1;
}

.color-blue {
    color: #5367fc;
}

.color-black,
.text-black {
    color: #4F4F4F;
}

.input-box select.input-form option.color-black {
    color: #4F4F4F;
}

.color-green {
    color: #00FFC1;
}

.color-dark-green {
    color: #008490;
}

.color-light-gray {
    color: #C9C9C9;
}

.position-relative {
    position: relative;
}

input,
input:focus {
    outline: none;
}

.cursor-pointer {
    cursor: pointer;
}
.cursor-auto {
    cursor: auto;
}
.title-1.mb-42 {
    margin-bottom: 42px;
}

.title-1.mb-36,
.title-2.mb-36 {
    margin-bottom: 36px;
}

.title-1 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #231F20;
}

h1.title-1 {
    margin-bottom: 1rem;
}

.title-2 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: #231F20;
}

.title-big {
    font-size: 33px;
    line-height: 51px;
    font-weight: 700;
    color: #231F20;
}

.modal-title-4 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: #231F20;
    max-width: 422px;
    width: 100%;
    margin-bottom: 0;
    text-align: center;
}

.para-1 {
    color: #797979;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.para-13 {
    color: #797979;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
}

.para-12 {
    color: #797979;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}
.para-11 {
    color: #797979;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
}

.ml-135 {
    margin-left: 135px;
}

.btn-action-box {
    display: block;
}

.btn-action-box .btn {
    display: inline-block;
    text-decoration: none;
    line-height: 38px;
    cursor: pointer;
    -webkit-appearance: button;
}

.btn-action-box a {
    display: inline-block;
    text-decoration: none;
}

.btn-plane {
    border: none;
    background: transparent;
    height: 50px;
    color: #231F20;
}

.btn-full {
    height: 50px;
    width: 100%;
    max-width: 398px;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    border-radius: 50px;
}

.btn-plane {
    line-height: 50px;
}

.btn-action-box .btn-plane {
    margin-top: 10px;
}

.font-12 {
    font-size: 12px;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.font-15 {
    font-size: 15px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-28 {
    font-size: 28px;
}

.font-32 {
    font-size: 32px;
}

.font-36 {
    font-size: 36px;
}
.lh-14 {
    line-height: 14px;
}
.lh-16 {
    line-height: 16px;
}
.lh-18 {
    line-height: 18px;
}
.lh-20 {
    line-height: 20px;
}
.lh-21 {
    line-height: 21px;
}
.lh-24 {
    line-height: 24px;
}
.lh-27 {
    line-height: 27px;
}
.lh-30 {
    line-height: 30px;
}
.lh-32 {
    line-height: 32px;
}
.lh-36 {
    line-height: 36px;
}
.lh-42 {
    line-height: 42px;
}
.lh-48 {
    line-height: 48px;
}
.lh-56 {
    line-height: 56px;
}
.mt-70 {
    margin-top: 70px;
}

.pt-70 {
    padding-top: 70px;
}

.font-300 {
    font-weight: 300;
}

.font-400 {
    font-weight: 400;
}

.font-500 {
    font-weight: 500;
}

.font-600 {
    font-weight: 600;
}

.font-700 {
    font-weight: 700;
}

.text-muted {
    opacity: 0.4;
}

.mr-1 {
    margin-right: 5px;
}

.mr-2 {
    margin-right: 10px;
}

.mr-3 {
    margin-right: 15px;
}

.mr-4 {
    margin-right: 20px;
}

.mr-5 {
    margin-right: 30px;
}

.ml-1 {
    margin-left: 5px;
}

.ml-2 {
    margin-left: 10px;
}

.ml-3 {
    margin-left: 15px;
}

.ml-4 {
    margin-left: 20px;
}

.ml-5 {
    margin-left: 30px;
}



.text-decoration {
    text-decoration: none;
}

.img-center,
.block-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

.site-header {
    position: absolute;
    width: 100%;
    padding-left: 40%;
    top: 0;
    right: 0;
    padding-top: 45px;
    padding-right: 45px;
}

.site-header .site-header-wrap {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.site-header .site-header-wrap p {
    color: #797979;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
}

.site-header .site-header-wrap div p {
    font-size: 18px;
    line-height: 27px;
}

.index-page .site-header {
    padding-top: 70px;
    padding-bottom: 55px;
}

.index-page .login-box {
    padding-top: 205px;
}

.main-wraper {
    display: flex;
}

.sidebar-box {
    width: 40%;
    /* height: 100%; */
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.bg-shape-white-line::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-image: url(../../../public/images/bg-shape-white.svg);
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-shape-white-line::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-image: url(../../../public/images/bg-shape-white-line.svg);
    background-repeat: no-repeat;
    background-position: center center;
}

.sidebar-box-wrap {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.sidebar-box .sidebox-main-img {
    width: auto;
    height: auto;
    max-width: 100%;
    z-index: 3;
}

.sidebar-img-text {
    position: absolute;
    top: 45px;
    left: 45px;
    max-width: 469px;
}

.sidebar-img-text p {
    text-align: left;
    color: #4F4F4F;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
}

.body-content-box {
    width: 60%;
    height: 100%;
    min-height: 100vh;
    background-color: #fff;
}

.login-box {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 150px 70px 0px 70px;
}

.login-box-wrap {
    width: 100%;
}

.login-box .logo-box {
    margin-bottom: 56px;
}

.login-box .logo-box img {
    max-width: 100%;
    height: auto;
}

.login-box {
    padding-bottom: 0;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
}

.login-box .login-box-wrap {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;    
}

.login-box .btn-action-box {
    padding-bottom: 40px;
    padding-top: 15px;
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
}

.title-box {
    margin-bottom: 50px;
}
.mutual-fund-page .title-box  {
    margin-bottom: 0px;
}
.login-box a.color-blue {
    font-weight: 400;
}

.login-box a.color-blue.font-600 {
    font-weight: 600;
}

.font-18-14 {
    font-size: 18px;
}

.modal-content {
    background-image: url(../../../public/images/bg-mobile-pattern.svg);
    background-repeat: no-repeat;
    /* background-position: center center; */
    background-size: cover;
    border-radius: 20px;
}

.bb-none {
    border-bottom: none;
}

.mw-468 {
    max-width: 468px;
}

.login-box .login-box-wrap.mw-468 {
    max-width: 468px;
}

.mw-445 {
    max-width: 445px;
}

.mw-400 {
    max-width: 400px;
}

.mw-250 {
    max-width: 250px;
}

.mw-100 {
    max-width: 100%;
}

.btn-link {
    outline: none;
    border: none;
    background: transparent;
    box-shadow: none;
    text-decoration: underline;
    cursor: pointer;
}

.form-box {
    margin-bottom: 16px;
}

.form-box p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.085em;
}

.input-box .input-form {
    width: 100%;
    max-width: 468px;
    height: 50px;
    border: 1px solid #5367FC;
    border-radius: 55px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #231F20;
}
.input-box .input-form-address {
    width: 100%;
    max-width: 468px;
    height: auto;
    border: 1px solid #5367FC;
    border-radius: 25px;
    padding-left: 30px;
    padding-right: 30px;
    min-height:  80px;
    /* font-size: 16px; */
    /* line-height: 24px; */
    /* font-weight: 500; */
    color: #231F20;
}

.index-page .input-box .input-form {
    height: 55px;
}

.input-box .input-form.color-blue {
    color: #5367fc;
}

.input-box .input-form.input-verified {
    background-image: url(../../../public/images/input-verified.svg);
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: calc(100% - 15px);
}

.input-box .input-form.not-verified {
    border-color: red;
}

.input-box select.input-form {
    background-image: url(../../../public/images/select-down-arrow.svg);
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: calc(100% - 22px);
    font-weight: 500;
}
input[type=date]::placeholder {
    text-transform: uppercase;
}
.input-box .input-form:disabled {
    background-color: #DFDFDF;
    color: #231F20!important;
    border-color: #DFDFDF;
}
.input-box .input-form:disabled::placeholder  {
    color: #231F20!important;
    opacity: 1;
}
.btn.disabled,
.btn.btn-secondary.disabled,
.btn.btn-secondary:disabled {
    color: #FFFFFF;
    background-color: #C9C9C9;
    border-color: #C9C9C9;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    opacity: 1;    
}
.input-box .input-form::placeholder,
input[type=date]::placeholder {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #9E9E9E;
}

.progress-inner .input-box .input-form::placeholder {
    font-size: 16px;
}
.progress-inner .input-box .input-form.input-pan-card::placeholder,
.progress-inner .input-box .input-date-box .react-datepicker-wrapper input::placeholder  {
    font-size: 18px;
    color: #9E9E9E;
}
.input-box.get-otp .input-form {
    padding-right: 190px;
}

.input-box.get-otp {
    margin-bottom: 55px;
}

.input-box.get-otp .input-get-otp {
    background-color: #5367FC;
    color: #fff;
    font-weight: 700;
    border-radius: 55px;
    height: 51px;
    border: none;
    width: 165px;
    font-size: 18px;
    position: absolute;
    right: 2px;
    top: 2px;
}
.input-box.get-otp .input-get-otp-disable {
    background-color: #808080;
    color: #fff;
    font-weight: 700;
    border-radius: 55px;
    height: 51px;
    border: none;
    width: 165px;
    font-size: 18px;
    position: absolute;
    right: 2px;
    top: 2px;
}

.input-box select.input-form option {
    padding: 10px;
    color: #5367FC;
    font-size: 16px;
    line-height: 27px;
    border-bottom: 1px solid #ccc;
    font-weight: 500;
    height: 50px;
}

input[type=checkbox] {
    width: 21px;
    height: 21px;
    min-width: 21px;
    min-height: 21px;
    border: 2px solid #444;
}

.or-box {
    margin-bottom: 55px;
}

.or-box span {
    display: inline-block;
    width: 60px;
}

.input-error-msg {
    color: #F15B4D;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-align: left;
    margin-top: 10px;
}

.index-page .or-box span {
    font-size: 25px;
    color: #231F20;
    opacity: 0.5;
    font-weight: 500;
}

.or-box span::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 195px;
    background: #231F20;
    left: 87px;
    top: 11px;
}

.or-box span::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 195px;
    background: #231F20;
    right: 87px;
    top: 11px;
}

.index-page .or-box span::before,
.index-page .or-box span::after {
    top: 21px;
}

.google-signin-box {
    background: #FAFAFA;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 37.5px;
    width: 468px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 60px;
    border: none;
}

.google-signin-box img {
    max-width: 100%;
    margin-right: 41px;
}

.google-signin-box p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.54);
    margin: 0;
}

.enter-otp-box>input {
    margin-right: 10px;
}

.enter-otp-box>input:last-child {
    margin-right: 0px;
}

.enter-otp-box input {
    background: #FFFFFF;
    border: 1px solid rgba(35, 31, 32, 0.25);
    border-radius: 5px;
    width: 60px;
    min-width: 60px;
    margin-right: 10px;
    height: 84px;
    color: #575757;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    font-weight: 600;
}

.w-90px {
    width: 90px;
}

.text-left {
    text-align: left;
}

.index-sub-title {
    max-width: 400px;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

input[type=date] {
    text-transform: uppercase;
}

.progress-list {
    margin: 22px 0 0 15px;
    padding: 0;
    display: flex;
}

.progress-list>li {
    list-style: none;
    width: 57px;
    height: 11px;
    background: #E9E9E9;
    border-radius: 10px;
    border: 1px solid #E9E9E9;
    cursor: pointer;
}

.progress-list>li>a {
    display: block;
    cursor: pointer;
}

.progress-list>li.current {
    border-color: #5367FC;
    background: #fff;
}

.progress-list>li.selected {
    border-color: #5367FC;
    background: #5367FC;
}

.continue-on-mobile {
    position: absolute;
    bottom: 55px;
    left: 45px;
    z-index: 3;
    width: 250px;
    height: 50px;
}

.continue-on-mobile a {
    color: #5367FC;
    font-weight: 700;
    text-align: center;
    font-size: 18px;
    width: 100%;
    display: inline-block;
    text-decoration: none;
    background-color: #fff;
    border-radius: 50px;
    line-height: 50px;
}

.continue-on-mobile a img {
    width: 30px;
    height: 25px;
    margin-right: 9px;
}

.bank-detail-box {
    background: rgba(0, 132, 144, 0.02);
    width: 100%;
    border: 0.5px solid #5367FC;
    border-radius: 5px;
    padding: 10px 23px;
}

.bank-detail-box .bank-detai-name {
    color: #231F20;
    margin-bottom: 2px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
}

.bank-detail-box .bank-detai-name img {
    margin-right: 15px;
}

.bank-detail-box .bank-detail-branch {
    color: #797979;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: left;
}

.bank-detail-box .bank-detail-ifsc {
    color: #797979;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: left;
}

.terms-selection-box label {
    cursor: pointer;
}

.terms-selection-box {
    padding: 30px 30px 20px 30px;
}

.terms-selection-box .terms-selection-wrap {
    max-height: 220px;
    padding-bottom: 0px;
    margin-bottom: 20px;
    overflow: auto;
}

.terms-selection-box .terms-selection-wrap::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.terms-selection-box .i-accept-terms-box {
    border-top: 1px solid #ccc;
    padding-top: 20px;
}



.selfie-box {
    text-align: center;
    width: 304px;
    height: 304px;
}

.selfie-box video,
.selfie-box img {
    width: 304px;
    height: 304px;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid #00FFC1;
}

.selfie-btn-box {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
}

.selfie-btn-box .btn {
    height: 50px;
    line-height: 38px;
    width: 175px;
    border-radius: 50px;
}

.selfie-or-box {
    margin-bottom: 5px;
    position: relative;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
}

.selfie-or-box span {
    display: inline-block;
    width: 30px;
    position: relative;
    font-size: 14px;
}

.selfie-or-box span::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 95px;
    background: #231F20;
    left: 40px;
    top: 10px;
    opacity: 0.45;
}

.selfie-or-box span::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 95px;
    background: #231F20;
    right: 40px;
    top: 10px;
    opacity: 0.45;
}

.selfie-or-box a {
    color: #C9C9C9;
    font-size: 18px;
    line-height: 27px;
    position: absolute;
    right: -95px;
    margin-top: -10px;
}

.selfie-guide-text {
    text-align: left;
    max-width: 280px;
    margin: 0 auto;
    position: relative;
}

p.selfie-guide-text::before {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 10px;
    background: #797979;
    left: -10px;
    top: 7px;
    position: absolute;
}

.signature-box canvas {
    border: 1px solid #ddd;
    box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.signature-box:focus,
.signature-box canvas:focus {
    background-color: #eee;
}

.upload-input {
    position: absolute;
    height: 80px;
    width: 175px;
    border-radius: 20px;
    right: 0;
    cursor: pointer;
    color: #212121;
    line-height: 75px;
    padding-top: 20px;
    font-size: 13px;
    text-align: center;
}

.upload-input::file-selector-button {
    opacity: 0;
    width: 1px;
}

.our-service-box {
    display: flex;
    justify-content: space-evenly;
}

.our-service-1 {
    background-color: #F2FFFC;
}

.our-service-2 {
    background-color: #FFFEF9;
}

.our-service-3 {
    background-color: #FFFAFA;
}

.our-service-4 {
    background-color: #F8F9FF;
}

.our-service-box .our-service-1.active,
.our-service-box .our-service-1.active .checkbox-container .checkmark {
    border: 1px solid #00FFC1;
}

.our-service-box .our-service-1.active .checkbox-container input:checked~.checkmark {
    background: #00FFC1;
}

.our-service-box .our-service-2.active,
.our-service-box .our-service-2.active .checkbox-container .checkmark {
    border: 1px solid #FFC727;
}

.our-service-box .our-service-2.active .checkbox-container input:checked~.checkmark {
    background: #FFC727;
}

.our-service-box .our-service-3.active,
.our-service-box .our-service-3.active .checkbox-container .checkmark {
    border: 1px solid #F15B4D;
}

.our-service-box .our-service-3.active .checkbox-container input:checked~.checkmark {
    background: #F15B4D;
}

.our-service-box .our-service-4.active,
.our-service-box .our-service-4.active .checkbox-container .checkmark {
    border: 1px solid #5367FC;
}

.our-service-box .our-service-4.active .checkbox-container input:checked~.checkmark {
    background: #5367FC;
}

.our-service-box .trading-service-item {
    width: 180px;
    height: 110px;
    box-shadow: 2px 2px 10px rgba(17, 69, 56, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #eee;
}

.our-service-box .trading-service-item:first-child {
    margin-right: 10px;
}

.our-service-box .trading-service-item label {
    cursor: pointer;
    height: 110px;
    width: 180px;
    border-radius: 8px;
}

.our-service-box .trading-service-item input {
    position: absolute;
    left: 3px;
    top: 3px;
    border-radius: 21px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.our-service-box .trading-service-item img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 5px;
    cursor: pointer;
    margin-top: 15px;
}

.our-service-box .trading-service-item span {
    text-align: center;
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: #231F20;
    cursor: pointer;
    font-weight: 500;
}

.input-form:focus-visible {
    outline: none;
}

/** Custome checkbox **/
.checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 0px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-container .checkmark {
    position: absolute;
    top: 3px;
    left: 3px;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #797979;
}

.checkbox-nationality .checkbox-container .checkmark {
    position: absolute;
    top: 4px;
    left: 7px;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid #797979;
}

.checkbox-nationality .checkbox-container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 9px;
}

.checkbox-container:hover input~.checkmark {
    /* background-color: #eee; */
}

.checkbox-container input:checked~.checkmark {
    background-color: #2196F3;
}

.checkbox-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked~.checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/** // Custome checkbox **/

.checkbox-nationality span.checkbox-text {
    padding-left: 28px;
}

.checkbox-nationality .checkbox-container .checkmark {
    border-radius: 2px;
}

.checkbox-nationality .checkbox-container input:checked~.checkmark {
    background-color: #797979;
}

.custom-select .select-hidden {
    display: none;
    visibility: hidden;
    padding-right: 10px;
}

.custom-select .select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 16px;
    color: #231F20;
    width: 100%;
    height: 50px;
    border-radius: 50px;
    border: 1px solid #5367FC;
}

.custom-select .select-styled {
    position: relative;
    background-color: #fff;
    padding: 0px 15px;
    line-height: 50px;
    border-radius: 50px;
    background-image: url(../../../public/images/select-down-arrow.svg);
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: calc(100% - 27px);
    font-weight: 500;
}

.custom-select .select-options {
    /* display: none; */
    position: absolute;
    top: 55px;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #fff;
    border: 0.25px solid rgb(35 31 32 / 25%);
    border-top: none;
    border-radius: 0px 0px 25px 25px;
    box-shadow: 0px 4px 15px 2px rgba(201, 201, 201, 0.15);
    overflow: hidden;
}

.custom-select .select-options li {
    margin: 0;
    padding: 12px 0;
    text-indent: 15px;
    border-top: 0.25px solid rgb(35 31 32 / 25%);
    color: #5367FC;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.custom-select .select-options li:hover,
.select-options li.is-selected {
    color: #5367FC;
    background: #f3f3f3;
}

.custom-select .select-options li[rel="hide"] {
    display: none;
}


.br-3{
    border-radius: 20px;
    overflow: hidden;
}



.radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
}

.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.radio-container .radiomark {
    position: absolute;
    top: 7px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #ccc;
}

.radio-container:hover input~.radiomark {
    background-color: #fff;
}

.radio-container input:checked~.radiomark {
    background-color: #fff;
    border: 2px solid #5367FC;
}

.radio-container input:checked~.radiomark+.radiomark-text {
    font-weight: 500;
}

.radio-container .radiomark:after {
    content: "";
    position: absolute;
    display: none;
}

.radio-container input:checked~.radiomark:after {
    display: block;
}

.radio-container .radiomark:after {
    top: 4px;
    left: 4px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #5367FC;
}

.radio-container .radiomark-text {
    font-size: 16px;
    margin-left: 10px;
}

.radio-container .radio-actions {
    display: none;
}

.radio-container input:checked~.radiomark-text .radio-actions {
    display: block;
}

.mobile-link {}

.mobile-link .or-box span::before {
    content: "";
    max-width: 165px;
    left: 60px;
}

.mobile-link .or-box span::after {
    content: "";
    max-width: 165px;
    right: 60px;
}

.mobile-link .input-box.get-otp .input-get-otp {
    max-width: 120px;
    height: 45px;
}

.mobile-link .input-box.get-otp .input-form {
    padding-right: 130px;
    padding-left: 20px;
}

.site-loader-box {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    opacity: 0.5;
}

.site-loader-img {
    width: 200px;
    height: 200px;
}

.multiselect-bottom-icon {
    position: absolute;
    right: 0px;
    top: 3px;
    cursor: pointer;
    transition: 0.4s;
}
.multiselect-bottom-icon.toggle-open {
    transform: rotate(180deg);
    transition: 0.4s;
}
/* ui css change */
.main-wraper .enter-otp-box input {
    background: #FFFFFF;
    border: 1px solid rgba(35, 31, 32, 0.25);
    border-radius: 5px;
    width: 63px;
    min-width: 63px;
    margin-right: 10px;
    height: 84px;
    color: #575757;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    font-weight: 600;
}
.main-wraper .enter-otp-box input::selection {
    background: #FFFFFF;
}
.main-wraper .enter-otp-box input:last-child {
    margin-right: 0px;
}
.disable {
    background-color: #C9C9C9;
    color: #fff;
    border: 1px solid #c9c9c9;
}

/* ui  css changes */
.radio-container .radiomark:after {
    top: 4px;
    left: 4px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #5367FC;
}

/* ///// */
/* ui css changes */

.MuiStack-root.css-1pduc5x-MuiStack-root,
.MuiStack-root.css-1pduc5x-MuiStack-root {
    padding: 0;
    margin-top: 25px;
}

.MuiStack-root.css-1pduc5x-MuiStack-root,
.MuiStack-root.css-1pduc5x-MuiStack-root>div {
    width: 100%;
}

.MuiStack-root.css-1pduc5x-MuiStack-root,
.MuiStack-root.css-1pduc5x-MuiStack-root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
    border-radius: 50px;
    /* border: 1px solid #dedede; */
}

.MuiStack-root.css-1pduc5x-MuiStack-root,
.MuiStack-root.css-1pduc5x-MuiStack-root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary input {
    border-radius: 50px;
}

.MuiStack-root.css-1pduc5x-MuiStack-root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 13px 0 13px 22px;
    text-align: center;
}

.MuiStack-root.css-1pduc5x-MuiStack-root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    margin: 0;
}

.upload-sign-box,
.capture-sign-box {
    text-align: left;
    position: relative;
}

.upload-sign-box a{
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    background-image: url(../../../public/images/file-upload.svg);
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 10px;
    padding-left: 35px;
    padding-right: 15px;
    margin-top: 5px;
}
.capture-sign-box a {
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    background-image: url(../../../public/images/capture-icon.svg);   
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 10px;
    padding-left: 35px;
    padding-right: 15px;
    margin-top: 5px;
    background-size: 18px;
}
.upload-sign-box input,
.capture-sign-box input {
    position: absolute;
    width: 130px;
    left: 0;
    top: 5px;
    opacity: 0;
    cursor: pointer;
}

.terms-selection-box .radio-container {
    padding-left: 22px;
    padding-right: 10px;
}

.terms-selection-box .radio-container .radiomark-text {
    display: block;
    font-size: 14px;
    margin-left: 0;
}

.terms-selection-box .radio-container .radiomark {
    top: 0px;
    height: 18px;
    width: 18px;
}

.terms-selection-box .radio-container .radiomark:after {
    top: 3px;
    left: 3px;
    width: 9px;
    height: 9px;
}

.terms-selection-box .radio-container input:checked~.radiomark+.radiomark-text {
    font-weight: normal;
}

.client-already-registered {
    background-color: burlywood;
}

.iframe-box {
    position: fixed;
    top: 0;
    right: 0;
    width: 59.94%;
    height: 100%;
    transform: translateX(0%);
    border: none;
    z-index: 10000;
    padding-left: 20px;
}

.input-date-box {
    width: 100%;
    position: relative;
}

.input-date-box .react-datepicker-wrapper,
.input-date-box label {
    display: block;
    width: 100%;
}

.input-date-box input {
    width: 100%;
    max-width: 468px;
    height: 50px;
    border: 1px solid #5367FC;
    border-radius: 55px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #231F20;
    text-align: center;
    /* background-image: url(../../../public/images/calendar-today.svg);
    background-repeat: no-repeat;
    background-size: 18px;
    background-position-y: center;
    background-position-x: calc(100% - 25px);
    cursor: pointer; */
}

.input-date-box .calendar-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    width: 65px;
    height: 49px;
    padding: 16px;
}

.sign-view-img {
    /* width: 200px; */
    height: auto;
    max-height: 140px;
    object-fit: contain;    
}
.multiple-income-upload {
    opacity: 0;
    height: 50px;
}
.income-proof-files {

}
.income-proof-files > ul {
   padding: 0 0 0 20px;
   margin: 0;
}
.income-proof-files > ul > li {
   list-style: disc;
   width: 100%;
   text-align: left;
   margin-bottom:2px;
   font-size: 13px;
}
.income-proof-files > ul > li > button {
    font-size: 10px;
    margin-left: 5px;
    outline: none;
}
.income-proof-files > ul > li > button:focus {
    outline: none;
    box-shadow: none;;
}
.body-content-box.verify-bank .screenshot {
opacity: '1';
position: absolute;
left: -9999px;
 width: 350px;
font-size: 12px;
} 

.body-content-box.verify-bank .kraScreenShot{
    opacity: '1';
    position: absolute;
    left: -9999px;
     width: 350px;
    font-size: 12px;
}
.ri-eye-line {
     position: absolute;
     margin-left: -50px;
     width: 50px;
     height: 45px;       
     border-radius: 0px 25px 25px 0;
     background-size: 20px;
     background-image: url(../../../public/images/view.png);
     background-position: center center;
     background-repeat: no-repeat;
     cursor: pointer;
}
.ri-eye-off-line {
    position: absolute;
    margin-left: -50px;
    width: 50px;
    height: 45px;      
    border-radius: 0px 25px 25px 0;
    background-size: 20px;
     background-image: url(../../../public/images/hide.png);
     background-position: center center;
     background-repeat: no-repeat;
     cursor: pointer;
}
/* .input-box .input-date-box .react-datepicker__tab-loop .react-datepicker-popper{
    transform: translate3d(155px, 50px, 0px) !important;
} */

/* mutual fund */
.mutual-fund-page .main-wraper {
    display: flex;
}
.mutual-fund-page .esign-pdf-sec .site-header {
    padding-top: 55px;
    padding-bottom: 55px;
}
.mutual-fund-page .site-header {
    position: absolute;
    width: 100%;
    padding-left: 40%;
    top: 0;
    right: 0;
    padding-top: 45px;
    padding-right: 45px;
}
.mutual-fund-page .site-header .site-header-wrap {
    display: flex;
    align-items: center;
    padding-left: 85px;
    padding-right: 85px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.mutual-fund-page .site-header .site-header-wrap .logo-box img {
    width: 120px;
}
.mutual-fund-page .sidebar-box {
    width: 40%;   
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.mutual-fund-page .sidebar-box-wrap {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.mutual-fund-page .bg-green {
    background-color: #00a77e;
}
.mutual-fund-page .bg-shape-white-line::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-image: url(../../../public/images/bg-shape-white.svg);
    background-repeat: no-repeat;
    background-position: center center;
}
.mutual-fund-page .bg-shape-white-line::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-image: url(../../../public/images/bg-shape-white-line.svg);
    background-repeat: no-repeat;
    background-position: center center;
}
.mutual-fund-page .sidebar-box .sidebox-main-img {
    width: auto;
    height: auto;
    max-width: 100%;
    z-index: 3;
}
.mutual-fund-page .body-content-box {
    width: 60%;
    height: 100%;
    min-height: 100vh;
    background-color: #fff;
}
.mutual-fund-page .login-box {
    display: flex;   
    justify-content: center;   
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 170px 85px 0px 85px;
}
.mutual-fund-page .login-box {
    padding-bottom: 0;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
}
.mutual-fund-page .title-h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 20px;
    text-align: left;
}
.mutual-fund-page .sub-title-p {   
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;  
    margin-top: 0; 
    margin-bottom: 0;
}
.mutual-fund-page .mw-550 {
    max-width: 480px;    
}
.mutual-fund-page .login-box-wrap {
    margin-left: 0;
}
.mutual-fund-page .color-green {
    color:#00a77e;
}
.mutual-fund-page .position-relative {
    position: relative;
}
.mutual-fund-page .login-box .btn-action-box {
    padding-bottom: 40px;
    padding-top: 15px;
    width: 100%;
    max-width: 480px;   
    margin-left: 0;
}
.mutual-fund-page .btn-full {
    height: 50px;
    width: 100%;
    max-width: 480px;
    font-weight: 700;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    border-radius: 50px;
}
.mutual-fund-page .btn-primary {
    background-color: #00a77e;
    border-color: #00a77e;
}
.mutual-fund-page .btn-primary:hover,
.mutual-fund-page .btn-primary:focus {
    background-color: #099976;
    border-color: #099976;
}
.mutual-fund-page .mutual-fund-content {

}
.mutual-fund-page .mutual-fund-content .mutual-fund-question {
    margin-bottom: 20px;
}
.mutual-fund-page .mutual-fund-content .q-title {   
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;  
    margin-top: 0; 
    margin-bottom: 8px;
    text-align: left;
}
.mutual-fund-page .mutual-fund-content  {

}
.mutual-fund-page .mutual-fund-content .radio-label {
    display: flex;
    width: 75px;
    max-width: 100%;
    align-items: center;
    cursor: pointer;
}
.mutual-fund-page .mutual-fund-content .radio-label span {
    margin-left: 5px;
}
.mutual-fund-page .mutual-fund-content .radio-label input[type=radio] {
    width: 22px;
    height: 22px;
    font-size: 16px;
    cursor: pointer;
}
.mutual-fund-page .mutual-fund-content input[type=text] {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    box-shadow: 0px 3px 6px 0px #eee;
    border-radius: 50px;
}
.mutual-fund-page .mutual-fund-content input[type=file] {
    width: 100%;
    height: 50px;
    padding: 13px 30px;
    box-shadow: 0px 3px 6px 0px #eee;
    border-radius: 50px;
}
/* Nominee sec */
.nominee-box{
    width: 100%;
    height: auto;
    position: relative;
    text-align: left;
  }
  .nominee-box p{
    font-size: 16px;
    font-weight: 600;
    color: #231F20;
  }
  .nominee-box .form-check-input:checked{
    background-color: #fff;
    border:2px solid #5367FC;
    position: relative;
  }
  .nominee-box .form-check-input:checked:before{
    content: '';
    width: 7px;
    height: 7px;
    background: #5367FC;
    position: absolute;
    border-radius: 50%;
    top: 3px;
    left: 3px;
  }
  .nominee-box .form-check{
    margin-right: 50px;
  }
  .nominee-box .form-check-input:focus{
    box-shadow: none;
  }
  /* .nominee-box .tab-box{
    border: 1px solid #EBEBEB;
    padding: 20px;
    margin-top: 20px;
  } */
  .nominee-box .nav-link{
    font-size: 14px;
    padding: 10px 0px 10px 0;
    color: #231F20;
    font-weight: 500;
    border: none;
    margin-right: 15px;
  }
  .nominee-box .nav-link.active{
    color: #5367FC;
    border: none;
    border-bottom: 3px solid #5367FC;
  }
  .nominee-box .tab-content{
    width: 100%;
    padding-top: 20px;
  }
  .nominee-box .form-group{
    /* width: 100%; */
    height: auto;
    margin-bottom: 8px;
  }
  .nominee-box  .form-group label{
    color: #231F20;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px; 
  }
  .nominee-box  .form-group.form-group-dob {
    position: relative;
  }
  .nominee-box  .form-group.form-group-dob span {
    position: absolute;
    left: 25px;
    display: inline-block;
    z-index: 99;
    font-size: 16px;
    color: #231f20a8;
    font-weight: 500;
    top: 11px;
  }
  .nominee-box  .form-group.form-group-dob input {
    position: relative;
    padding-left: 60px;
  }
  .nominee-box .card-body .form-group label{
    display: none;
  }
  .nominee-box  .form-group .form-control{
    height: 45px;
    background: #f6f7ff;
    border: 1px solid #E0E3FF;
    -moz-appearance: auto;
    appearance: auto;
    margin-bottom: 8px;
  }
  .nominee-box  .form-group .form-control::placeholder{
    color: #DADADA;
  }
  .nominee-box .form-group .form-control:focus{
    box-shadow: none;
  }
  .nominee-box  .form-group span{
    color: #787878;
    font-size: 14px;
    position: relative;
    top: 2px;
  }
  .nominee-box .save-btn{
    font-size:14px;
    color: #fff;
    font-weight: 500;
    padding: 10px;
    border-radius: 30px;
    width: 100%;
    background: #5367FC;
    border: none;
    margin-top: 10px; 
  }
  #accordion .card-header a,   
  #accordion .card-header a:focus {
     outline: none;
  }
.nominee-box .card-body .form-group input::placeholder {
    color: #444;
}
.nominee-box .multiselect-bottom-icon-2 {
    right: 12px;
    top: 12px;
    position: absolute;  
    cursor: pointer;
    transition: 0.4s;
}
.loader-img-20 img {
    width: 25px;
}
  @media (min-width: 1200px) {
    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%;
    }
  }