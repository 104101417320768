.card {
    background-color: #f5f7ff;
    border: 1px solid #d3e0ff;
    border-radius: 15px;
    padding: 20px 30px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    font-family: Arial, sans-serif;
    color: #333;
    width: 300px;
    margin: 20px auto;
}

.card-item {
    display: flex;
    justify-content: space-between;
}

.label {
    font-weight: bold;
}

.value {
    color: #333;
}

.required {
    color: red;
}
