@media(max-width: 1440px) {
    .bg-shape-white-line::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        background-image: url(../../../public/images/bg-shape-white.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }

    .bg-shape-white-line::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        background-image: url(../../../public/images/bg-shape-white-line.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }
}

@media(max-width: 1200px) {
    .sidebar-box .sidebox-main-img {
        max-width: calc(100% - 60px);
    }

    .bg-shape-white-line::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        background-image: url(../../../public/images/bg-shape-white.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: calc(100% - 30px);
    }

    .sidebar-img-text {
        max-width: calc(100% - 60px);
        top: 30px;
        left: 30px;
    }
}

@media(max-width: 991px) {
    .enter-otp-box input {
        width: 50px;
        min-width: 50px;
        height: 60px;
    }

    .or-box span::before {
        content: "";
        width: 150px;
        left: 60px;
    }

    .or-box span::after {
        content: "";
        width: 150px;
        right: 60px;
    }

    .google-signin-box {
        margin-left: auto;
        margin-right: auto;
        width: 340px;
    }

    .progress-list>li {
        width: 40px;
        height: 8px;
    }
    .main-wraper .enter-otp-box input {
        width: 48px;
        min-width: 48px;
        height: 64px;
    }

}

@media(min-width: 768px) {
    .mw-468 .input-box input[type=email] {
        height: 55px;
    }

    .login-box.pt-120 {
        padding-top: 120px;
    }

    .pl-md-60 {
        margin-left: 60px;
    }
}


@media(max-width: 767px) {
    .main-wraper {
        display: block;
    }

    .sidebar-box,
    .sidebar-box-wrap,
    .body-content-box {
        height: auto;
        min-height: auto;
        width: 100%;
    }

    .sidebar-img-text,
    .continue-on-mobile {
        display: none;
    }

    .login-box {
        display: flex;
        text-align: center;
        width: 100%;
        min-height: 100%;
        padding: 20px 24px 90px 24px;
    }

    .bg-shape-white-line::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        background-image: url(../../../public/images/bg-shape-gray.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }

    .bg-shape-white-line::after {
        display: none;
    } 
    .sidebar-box .bg-blue,
    .sidebar-box .bg-green {
        background-color: transparent;
    }

    .index-page .login-box {
        padding-top: 70px;
        padding-bottom: 15px;
    }

    .index-page .site-header {
        padding-top: 30px;
        padding-bottom: 35px;
        padding-left: 0;
        padding-right: 0;
        position: relative;
    }

    .main-wraper::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        background-image: url(../../../public/images/bg-mobile-pattern.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    .or-box span::before {
        content: "";
        position: absolute;
        height: 1px;
        width: 73px;
        background: #231F20;
        left: 50px;
        top: 11px;
    }

    .or-box span::after {
        content: "";
        position: absolute;
        height: 1px;
        width: 73px;
        background: #231F20;
        right: 50px;
        top: 11px;
    }

    .google-signin-box {
        width: 100%;
        height: 55px;
    }

    .google-signin-box p {
        line-height: 21px;
        font-size: 18px;
    }

    .google-signin-box img {
        max-width: 100%;
        margin-right: 24px;
        width: 24px;
    }

    .site-header .site-header-wrap {
        padding-left: 20px;
        padding-right: 20px;
        max-width:440px
    }
    .site-header .site-header-wrap > div{
      width:100%;
    }
    .progress-list>li {
        width: 12.5%;
    }
    .progress-list {
        margin: 10px 0 0 10px;
    }

    .site-header img {
        width: 45px;
    } 

    .index-page .site-header .logo-box img {
        width: 146px;
    }

    .input-box.get-otp .input-get-otp {
        font-weight: 500;
        width: 110px;
        font-size: 18px;
    }
    .input-box.get-otp .input-get-otp-disable {
        font-weight: 500;
        width: 110px;
        font-size: 18px;
    }

    .input-box .input-form {
        padding-left: 20px;
    }

    .enter-otp-box input {
        width: 45px;
        min-width: 45px;
        height: 60px;
        margin-right: 8px;
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
    }

    .site-header {
        position: relative;
        width: 100%;
        padding-left: 0;
        padding-top: 30px;
        padding-right: 0;
        padding-bottom: 50px;
    }
    .site-header.pb-2 {
        padding-bottom: 30px!important;
    }
    .sidebar-box .sidebox-main-img {
        max-width: 200px;
        max-height: 120px;
        z-index: 1;
    }

    .index-page .sidebar-box .sidebox-main-img {
        max-width: 240px;
        max-height: 200px;
    }

    .site-header .site-header-wrap h1 {
        padding-right: 0;
    }

    .site-header .site-header-wrap div h1 {
        font-size: 16px;
        line-height: 24px;
    }

    .index-sub-title {
        font-size: 16px;
        line-height: 24px;
    }

    .form-box p.color-blue {
        font-size: 16px;
        line-height: 24px;
    }

    .input-box .input-form::placeholder {
        font-size: 16px;
        line-height: 24px;
    }

    .progress-list {
        margin: 10px 0 0 15px;
    }

    .login-box .btn-action-box {
        padding: 10px 24px 10px 24px;
        max-width: 100%;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
    }

    .login-box .btn-action-box .btn {
        max-width: 400px;
    }

    .input-box.get-otp .input-form {
        padding-right: 125px;
    }

    .title-1.mb-42 {
        margin-bottom: 8px;
    }

    .title-1.mb-36,
    .title-2.mb-36 {
        margin-bottom: 8px;
    }

    .title-2 {
        font-size: 20px;
        line-height: 24px;
    }

    .font-18-14 {
        font-size: 14px;
    }

    .btn-action-box .btn-plane {
        margin-top: 0px;
    }

    .login-box.pt-120 {
        padding-bottom: 10px;
    }

    .selfie-or-box a {
        display: none;
    }

    .terms-selection-box {
        padding: 0;
    }

    .selfie-guide-text {
        max-width: 100%
    }

    .radio-container .radiomark-text {
        margin-left: 0px;
    }

    .mobile-d-none {
        display: none;
    }

    .index-page .or-box span {
        font-size: 16px;
    }

    .index-page .or-box span::before,
    .index-page .or-box span::after {
        top: 13px;
    }

    .kyc-done .sidebox-main-img {
        max-width: 280px;
        max-height: 220px;
    }

    .kyc-done .login-box {
        padding: 20px 24px 80px 24px;
    }

    .index-page .or-box {
        margin-bottom: 20px;
    }

    .index-page .title-box,   
    .input-box.get-otp {
        margin-bottom: 30px;
    }

    .index-page .login-box {
        padding-top: 40px;
        margin-bottom: 0;
        padding-bottom: 15px;
    }

    .selfie-page .sidebar-box {
        display: none;
    }
    .draw-signature-module .sidebar-box {
        display: none;
     }
     .iframe-box {
        min-height: 100vh;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom:0;
        height: 100%;
        width: 100%;
        z-index: 10000;  
        padding-left: 0;     
        padding-right: 0;
    }
    .input-date-box input {
        background-position-x: calc(100% - 20px);
    }
   
}

@media(max-width: 575px) {
    .modal-dialog {
        margin-right: auto;
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .progress-list {
        margin: 10px 0 0 10px;
    }

    .progress-list>li {
        width: 12.5%;
        height: 6px;
    }

    .our-service-box .trading-service-item,
    .our-service-box .trading-service-item label {
        width: 140px;
        height: 110px;
    }

    .selfie-box {
        width: 270px;
        height: 270px;
    }

    .selfie-box video,
    .selfie-box img {
        width: 270px;
        height: 270px;
    }
    .signature-box canvas {       
        max-width: 335px;
    }
}

@media(max-width: 380px) {
    .main-wraper .enter-otp-box input {
        width: 40px;
        min-width: 40px;
        height: 50px;
        font-size: 16px;
    }

    .our-service-box .trading-service-item img {
        max-width: 70px;
    }

    .our-service-box .trading-service-item,
    .our-service-box .trading-service-item label {
        width: 120px;
        height: 80px;
    }

    .our-service-box .trading-service-item span {
        font-size: 11px;
        line-height: 16px;

    }
    .signature-box canvas {       
        max-width: 275px;
    }
}


@media(min-height: 440px) and (max-height: 730px) and (min-width:1024px) {
    .index-page .site-header {
        padding-top: 50px;
        padding-bottom: 30px;
    }

    .index-page .login-box {
        padding-top: 160px;
    }

    .input-box.get-otp {
        margin-bottom: 40px;
    }

    .or-box {
        margin-bottom: 30px;
    }

    .site-header {
        padding-top: 35px;
    }

    .login-box {
        padding: 120px 70px 0px 70px;
    }

    .login-box .btn-action-box {
        padding-bottom: 30px;
    }

    .title-1.mb-42 {
        margin-bottom: 30px;
    }

    .enter-otp-box input {
        width: 55px;
        min-width: 55px;
        height: 60px;
    }

    .input-box .input-form {
        height: 45px;
        line-height: 45px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .btn-full {
        height: 45px;
        line-height: 34px;
    }

    .btn-action-box .btn {
        line-height: 35px
    }

    .bank-detail-box {
        padding: 8px 20px;
    }

    .selfie-box,
    .selfie-box video,
    .selfie-box img {
        width: 220px;
        height: 220px;
    }

    .bank-detail-box p {
        margin-bottom: 0.125rem !important;
        font-size: 12px;
    }

    .mb-h740-1 {
        margin-bottom: 0.1250rem !important;
    }

    .mb-h740-2 {
        margin-bottom: 0.250rem !important;
    }

    .mb-h740-3 {
        margin-bottom: 0.50rem !important;
    }

    .mb-h740-4 {
        margin-bottom: 1rem !important;
    }

    .mb-h740-5 {
        margin-bottom: 1.50rem !important;
    }

    .mb-h740-6 {
        margin-bottom: 2rem !important;
    }

    .pb-h740-1 {
        padding-bottom: 0.1250rem !important;
    }

    .pb-h740-2 {
        padding-bottom: 0.250rem !important;
    }

    .pb-h740-3 {
        padding-bottom: 0.50rem !important;
    }

    .pb-h740-4 {
        padding-bottom: 1rem !important;
    }

    .pb-h740-5 {
        padding-bottom: 1.50rem !important;
    }

    .pb-h740-6 {
        padding-bottom: 2rem !important;
    }

    .bank-detail-box .bank-detai-name {
        font-size: 14px;
        line-height: 18px;
    }

    .bg-shape-white-line::before {
        content: "";
        width: calc(100% - 50px);
    }

    .sidebar-box .sidebox-main-img {
        max-width: calc(100% - 140px);
    }

    .continue-on-mobile {
        bottom: 30px;
    }

    .signature-box {
        max-width: 340px;
    }

    .btn-action-box .btn-plane {
        margin-top: 0px;
    }

    .radio-container .radiomark-text {
        font-size: 15px;
        margin-left: 5px;
    }

    .radio-container {
        margin-bottom: 5px;
    }

    .radio-container .radiomark {
        top: 5px;
    }

    .terms-selection-box .terms-selection-wrap {
        max-height: 190px;
        margin-bottom: 10px;
    }

    .terms-selection-box p {
        margin-bottom: 10px;
    }

    input[type=checkbox] {
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
    }

    .terms-selection-box .para-1 {
        color: #797979;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
    }

    .terms-selection-box {
        padding: 5px 30px 5px 30px;
    }

    .terms-selection-box .i-accept-terms-box {
        padding-top: 15px;
    }

    .our-service-box .trading-service-item {
        width: 140px;
        height: 75px;
        margin-bottom: 12px;
    }

    .our-service-box .trading-service-item label {
        width: 140px;
    }

    .our-service-box .trading-service-item img {
        margin-bottom: 5px;
        margin-top: 5px;
        max-width: 70px;
    }

    .kyc-done-congrats .login-box.pt-120 {
        padding-top: 90px;
    }

    .kyc-done-congrats .login-box-wrap img.mb-4 {
        margin-bottom: 0.5rem !important;
    }

    .kyc-done-congrats .title-1 {
        font-size: 18px;
        line-height: 24px;
    }

    .kyc-done-congrats .para-1 {
        font-size: 14px;
        line-height: 20px;
    }

    .kyc-done-congrats .internal-btn-box {
        margin-top: 0.5rem !important;
        margin-bottom: 1rem !important;
    }

}
@media (max-width: 767px) {
    .mutual-fund-page .main-wraper {
        display: block;
    }
    .mutual-fund-page .main-wraper::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        background-image: url(../../../public/images/bg-mobile-pattern.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
    .mutual-fund-page .esign-pdf-sec .site-header {
        padding-top: 20px;
        padding-bottom: 15px;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        width: 100%;
    }
    .mutual-fund-page .site-header .site-header-wrap {
        padding-left: 25px;
        padding-right: 25px;
    }
    .mutual-fund-page .esign-pdf-sec .site-header .logo-box img {
        width: 100px;
    }
    .mutual-fund-page .sidebar-box, .mutual-fund-page .sidebar-box-wrap, .mutual-fund-page .body-content-box {
        height: auto;
        min-height: auto;
        width: 100%;
    }
    .mutual-fund-page .sidebar-box, .mutual-fund-page .sidebar-box-wrap, .mutual-fund-page .body-content-box {
        height: auto;
        min-height: auto;
        width: 100%;
    }
    .mutual-fund-page .sidebar-box .bg-green {
        background-color: transparent;
    }
    .mutual-fund-page .bg-shape-white-line::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        background-image: url(../../../public/images/bg-shape-gray.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }
    .mutual-fund-page .esign-pdf-sec .sidebar-box .sidebox-main-img {
        max-width: 280px;
        max-height: 240px;
    }
    .mutual-fund-page .sidebar-box, .mutual-fund-page .sidebar-box-wrap, .mutual-fund-page .body-content-box {
        height: auto;
        min-height: auto;
        width: 100%;
    }
    .mutual-fund-page .esign-pdf-sec .login-box {
        padding-top: 30px;
    }
    .mutual-fund-page .title-box, .login-box {
        margin-bottom: 30px;
    }
    .mutual-fund-page .title-h1 {
        font-size: 22px;
        font-weight: 700;
        line-height: 27px;
        margin-bottom: 0;
    }
    .mutual-fund-page .sub-title-p {  
        font-size: 14px;
        line-height: 24px;
    }
    .mutual-fund-page .login-box {
        padding-left: 25px;
        padding-right: 25px;
        min-height: auto;
    }
    .mutual-fund-page .login-box .btn-action-box {
        padding-bottom: 0px;
    }
    .mutual-fund-page .mutual-fund-content .q-title {   
        font-size: 18px;   
        margin-bottom: 4px;
    }
    .mutual-fund-page .mutual-fund-content .mutual-fund-question {
        margin-bottom: 20px;
    }
    .mutual-fund-page .esign-pdf-sec .sidebar-box .sidebox-main-img {
        max-width: 240px;
        max-height: 200px;
        margin-left: 25px;
    }
    .mutual-fund-page .sidebar-box-wrap {
        justify-content: flex-start;
    }
    
    }