.kyc-journey {
	width: 100%;
	height: auto;
	position: relative;
}

.kyc-journey .left-col {
	padding: 0;
}

.kyc-journey .left-part {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	position: relative;
	background: #122DFD url('../../../public/img/blue-back.png') no-repeat;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.kyc-journey .left-part.green {
	background: #fff url('../../../public/img/green-back.png') no-repeat;
	background-position: center;
}

.kyc-journey .left-part .image {
	width: 100%;
	height: auto;
	position: relative;
	padding: 0 50px;
}

.kyc-journey .left-part .image img {
	width: 100%;
}

.kyc-journey .main-containt {
	width: 100%;
	height: auto;
	min-height: 100vh;
	padding: 0 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff url('../../../public/img/white-back.png') no-repeat;
	background-position: right bottom;
	display: flex;

}

.kyc-journey .main-containt .text {
	width: 100%;
	height: auto;
	text-align: center;
}

.kyc-journey .main-containt .text .logo {
	display: table;
	margin: auto;
	margin-bottom: 40px;
}

.kyc-journey .main-containt .text h5 {
	font-size: 20px;
	color: #000;
	font-weight: 700;
	margin-bottom: 10px;
}

.kyc-journey .main-containt .text h5 span {
	color: #008490;
}

.kyc-journey .main-containt .text h4 {
	font-size: 40px;
	font-weight: 700;
	color: #263238;
	line-height: 56px;
	margin-bottom: 40px;
}

.kyc-journey .main-containt .text h4 span {
	color: #5367FC;
}

.kyc-journey .main-containt .text .form-group {
	width: 100%;
	height: auto;
	position: relative;
	border: 1px solid #5367FC;
	border-radius: 30px;
	display: flex;
	align-items: center;
}

.kyc-journey .main-containt .text .form-group .form-control {
	border: none;
	border-radius: 30px;
	padding: 15px 25px;
}

.kyc-journey .main-containt .text .form-group .form-control:focus {
	box-shadow: none;
}

.kyc-journey .main-containt .text .form-group .go-btn {
	background: #5367FC;
	color: #fff;
	border-radius: 30px;
	padding: 15px 30px;
	white-space: nowrap;
	border: 1px solid #fff;
	font-size: 18px;
	font-weight: 700;
}

.go-btn:hover {
	background-color: #00FFC1 !important;
	color: #231F20 !important;
	/* border-radius: 30px;
	padding: 15px 30px;
	white-space: nowrap;
	border:1px solid #fff;
	font-size: 18px;
	font-weight: 700; */
}

.kyc-journey .road-map {
	width: 100%;
	height: auto;
	position: relative;
	padding: 30px 70px;
}

.kyc-journey .road-map .top-text {
	width: 100%;
	height: auto;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.kyc-journey .road-map .top-text p {
	font-size: 14px;
	font-weight: 500;
	color: #231F20;
	margin-bottom: 8px;
}

.kyc-journey .road-map .top-text p span {
	color: #5367FC;
}

.kyc-journey .road-map .road {
	width: 400px;
	height: auto;
	position: relative;
	margin: auto;
	padding: 10px 0;
}

.kyc-journey .heading {
	display: table;
	background: #fff;
	border-radius: 30px;
	color: #A6A6A6;
	margin: auto;
	-webkit-box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.25);
	padding: 10px 30px;
	position: relative;
	z-index: 99;
}

.kyc-journey .heading p {
	font-size: 18px;
	font-weight: 500;
	margin: 0;
}

.kyc-journey .road-map .road .item {
	width: 100%;
	height: auto;
	min-height: 110px;
	position: relative;
	width: 100%;
	height: auto;
	padding: 20px;
	display: table;
}

.kyc-journey .road-map .road .item.first:before {
	content: '';
	position: absolute;
	height: 50%;
	width: 20%;
	right: 50%;
	border: 2px dashed #A6A6A6;
	border-left: none;
	border-top: none;
	border-radius: 0 0px 60px 0;
	top: -48%;
}

.kyc-journey .road-map .road .item.first:after {
	content: '';
	position: absolute;
	height: 50%;
	width: 30%;
	left: 0;
	border: 2px dashed #A6A6A6;
	border-right: none;
	border-bottom: none;
	border-radius: 60px 0 0px 0px;
	top: 0;
}

.kyc-journey .road-map .road .item.second:before {
	content: '';
	position: absolute;
	height: 50%;
	width: 50%;
	left: 0;
	border: 2px dashed #A6A6A6;
	border-right: none;
	border-top: none;
	border-radius: 0px 0 0 60px;
	top: -48%;
}

.kyc-journey .road-map .road .item.second:after {
	content: '';
	position: absolute;
	height: 50%;
	width: 50%;
	right: 0;
	border: 2px dashed #A6A6A6;
	border-left: none;
	border-bottom: none;
	border-radius: 0px 60px 0 0px;
	top: 0;
}

.kyc-journey .road-map .road .item.third:before {
	content: '';
	position: absolute;
	height: 50%;
	width: 50%;
	right: 0;
	border: 2px dashed #A6A6A6;
	border-left: none;
	border-top: none;
	border-radius: 0px 0 60px 0px;
	top: -48%;
}

.kyc-journey .road-map .road .item.third:after {
	content: '';
	position: absolute;
	height: 50%;
	width: 50%;
	left: 0;
	border: 2px dashed #A6A6A6;
	border-right: none;
	border-bottom: none;
	border-radius: 60px 0 0px;
	top: 0;
}

.kyc-journey .road-map .road .item.btn-item:before {
	content: '';
	position: absolute;
	height: 50%;
	width: 30%;
	left: 0%;
	border: 2px dashed #A6A6A6;
	border-right: none;
	border-top: none;
	border-radius: 0 0px 0 60px;
	top: -48%;
}

.kyc-journey .road-map .road .item.btn-item:after {
	content: '';
	position: absolute;
	height: 50%;
	width: 20%;
	right: 50%;
	border: 2px dashed #A6A6A6;
	border-left: none;
	border-bottom: none;
	border-radius: 0 60px 0px 0;
	top: 0;
}

.kyc-journey .point {
	width: auto;
	display: flex;
	background: #fff;
	border-radius: 50px;
	color: #A6A6A6;
	-webkit-box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.25);
	padding: 10px 10px;
	align-items: center;
	width: 80%;
}

.kyc-journey .point.right {
	text-align: right;
	justify-content: flex-end;
	float: right;
}

.kyc-journey .point p {
	font-size: 18px;
	font-weight: 500;
	margin: 0;
}

.kyc-journey .point span {
	color: #A6A6A6;
	font-size: 12px;
}

.kyc-journey .point .icon {
	width: 38px;
	height: 38px;
	border-radius: 50%;
	flex: 0 0 38px;
	border: 2px solid #A6A6A6;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}

.kyc-journey .point.right .icon {
	margin-right: 0;
	margin-left: 10px;
}

.kyc-journey .road-map .road .item .ready-btn {
	display: table;
	padding: 10px 30px;
	background: #EBEBEB;
	border-radius: 50px;
	margin: auto;
	border: none;
	color: rgba(35, 31, 32, 0.6);
	margin-top: 15px;
	-webkit-box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.25);
	position: relative;
	z-index: 9;
	text-decoration: none;
}

.kyc-journey .road-map .road .item .dots {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border: 2px solid #A6A6A6;
	background: #fff;
	z-index: 9;
	position: absolute;
	left: -12px;
	top: 50%;
	transform: translateY(-50%);
}

.kyc-journey .road-map .road .item.second .dots {
	left: unset;
	right: -12px;
}

.kyc-journey .road-map .road .completed .dots {
	border-color: #5367FC;
}

.kyc-journey .road-map .road .rejected .dots {
	border-color: #f00;
}

.kyc-journey .road-map .road .completed .dots:before {
	content: '';
	width: 17px;
	height: 17px;
	border-radius: 50%;
	background: #5367FC;
	position: absolute;
	top: 2px;
	left: 2px;
}

.kyc-journey .road-map .road .rejected .dots:before {
	content: '';
	width: 17px;
	height: 17px;
	border-radius: 50%;
	background: #f00;
	position: absolute;
	top: 2px;
	left: 2px;
}

.kyc-journey .completed .heading {
	color: #231F20;
}

.kyc-journey .road-map .road .completed.first:before {
	border: 2px solid #5367FC;
	border-left: none;
	border-top: none;
}

.kyc-journey .road-map .road .completed.first:after {
	border: 2px solid #5367FC;
	border-right: none;
	border-bottom: none;
}

.kyc-journey .road-map .road .completed.second:before {
	border: 2px solid #5367FC;
	border-right: none;
	border-top: none;
}

.kyc-journey .road-map .road .completed.second:after {
	border: 2px solid #5367FC;
	border-left: none;
	border-bottom: none;
}

.kyc-journey .road-map .road .rejected.second:before {
	border: 2px solid #f00;
	border-right: none;
	border-top: none;
}

.kyc-journey .road-map .road .rejected.second:after {
	border: 2px solid #f00;
	border-left: none;
	border-bottom: none;
}

.kyc-journey .road-map .road .completed.third:before {
	border: 2px solid #5367FC;
	border-left: none;
	border-top: none;
}

.kyc-journey .road-map .road .completed.third:after {
	border: 2px solid #5367FC;
	border-right: none;
	border-bottom: none;
}

.kyc-journey .road-map .road .completed.btn-item:before {
	border: 2px solid #5367FC;
	border-right: none;
	border-top: none;
}

.kyc-journey .road-map .road .completed.btn-item:after {
	border: 2px solid #5367FC;
	border-left: none;
	border-bottom: none;
}

.kyc-journey .completed .point p {
	color: #231F20;
}

.kyc-journey .completed .point span {
	color: #5367FC;
	font-size: 16px;
}

.kyc-journey .completed .point .icon {
	border-color: #5367FC;
}

.kyc-journey .pending .point p {
	/* color: #231F20; */
}

.kyc-journey .pending .point span {
	/* color: #5367FC; */
	font-size: 12px;
}

.kyc-journey .pending .point .icon {
	/* border-color:#5367FC;  */
}

.kyc-journey .rejected .point p {
	color: #231F20;
}

.kyc-journey .rejected .point span {
	color: #f00;
	font-size: 12px;
}

.kyc-journey .rejected .point .icon {
	border-color: #f00;
}

.kyc-journey .road-map .road .item.completed .ready-btn {
	background: #fff;
	color: #5367FC;
	background: #fff url('../../../public/img/s-Logo.svg') no-repeat;
	background-size: 15px;
	background-position-x: 10px;
	background-position-y: center;
}

.ready-trade-modal .modal-body {
	text-align: center;
}

.ready-trade-modal .modal-body .btn-close {
	position: absolute;
	right: 15px;
	top: 15px;
}

.ready-trade-modal .modal-body h5 {
	color: #231F20;
	font-size: 16px;
	font-weight: 500;
}

.ready-trade-modal .modal-body h4 {
	color: #5367FC;
	font-size: 20px;
	font-weight: 700;
}

.site-rocket-box {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 99;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}

.site-rocket-img {
	width: 45%;
	height: auto;
}

@media screen and (max-width: 992px) {
	.kyc-journey .main-containt {
		min-height: unset;
		padding: 30px 0;
	}

	.kyc-journey .main-containt .text .logo img {
		width: 180px;
	}

	.kyc-journey .main-containt .text h5 {
		font-size: 18px;
	}

	.kyc-journey .main-containt .text h4 {
		font-size: 30px;
		line-height: 35px;
	}

	.kyc-journey .main-containt .text .form-group .go-btn {
		font-size: 16px;
		padding: 10px 15px;
	}

	.kyc-journey .main-containt .text .form-group .form-control {
		padding: 10px;
	}

	.kyc-journey .left-part {
		min-height: unset;
		padding: 100px 0px;
	}

	.kyc-journey .road-map {
		height: auto;
		padding: 20px 0;
	}

	.kyc-journey .road-map .top-text {
		display: block;
		text-align: center;
	}

	.kyc-journey .road-map .top-text p {
		font-size: 14px;
	}

	.kyc-journey .road-map .road {
		width: 100%;
		padding: 0 20px;
	}

	.kyc-journey .heading p {
		font-size: 14px;
	}

	.kyc-journey .road-map .road .item {
		width: 100%;
		padding: 20px 25px 20px;
		display: block;
		min-height: 115px;
	}

	.kyc-journey .point.right {
		width: 100%;
	}

	.kyc-journey .point {
		width: 100%;
	}
}

.celebration {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	display: table;
	z-index: 99;
}

@media only screen and (min-width: 1367px) {
	.kyc-journey .road-map .road .item.first:before {
		border: 3px dashed #A6A6A6;
		border-left: none;
		border-top: none;

	}

	.kyc-journey .road-map .road .completed.first:before {
		border: 3px solid #5367FC;
		border-left: none;
		border-top: none;
	}

	.kyc-journey .road-map .road .item.first:after {
		border: 3px dashed #A6A6A6;
		border-right: none;
		border-bottom: none;
	}

	.kyc-journey .road-map .road .completed.first:after {
		border: 3px solid #5367FC;
		border-right: none;
		border-bottom: none;
	}

	.kyc-journey .road-map .road .item.second:before {
		border: 3px dashed #A6A6A6;
		border-right: none;
		border-top: none;
	}

	.kyc-journey .road-map .road .completed.second:before {
		border: 3px solid #5367FC;
		border-right: none;
		border-top: none;
	}

	.kyc-journey .road-map .road .item.second:after {
		border: 3px dashed #A6A6A6;
		border-left: none;
		border-bottom: none;
	}

	.kyc-journey .road-map .road .completed.second:after {
		border: 3px solid #5367FC;
		border-left: none;
		border-bottom: none;
	}

	.kyc-journey .road-map .road .item.third:before {
		border: 3px dashed #A6A6A6;
		border-left: none;
		border-top: none;
	}

	.kyc-journey .road-map .road .completed.third:before {
		border: 3px solid #5367FC;
		border-left: none;
		border-top: none;
	}

	.kyc-journey .road-map .road .item.third:after {
		border: 3px dashed #A6A6A6;
		border-right: none;
		border-bottom: none;
	}

	.kyc-journey .road-map .road .completed.third:after {
		border: 3px solid #5367FC;
		border-right: none;
		border-bottom: none;
	}

	.kyc-journey .road-map .road .item.btn-item:before {
		border: 3px dashed #A6A6A6;
		border-right: none;
		border-top: none;
	}

	.kyc-journey .road-map .road .completed.btn-item:before {
		border: 3px solid #5367FC;
		border-right: none;
		border-top: none;
	}

	.kyc-journey .road-map .road .item.btn-item:after {
		border: 3px dashed #A6A6A6;
		border-left: none;
		border-bottom: none;
	}

	.kyc-journey .road-map .road .completed.btn-item:after {
		border: 3px solid #5367FC;
		border-left: none;
		border-bottom: none;
	}
	.kyc-journey .road-map .road .rejected.second:before {
		border: 2px solid red;
		border-right: none;
		border-top: none;
	}
	.kyc-journey .road-map .road .rejected.second:after {
		border: 2px solid #f00;
		border-left: none;
		border-bottom: none;
	}

}